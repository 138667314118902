import React, { createContext } from 'react';
import { useAes } from '@tunaiku.npm/react-library/dist/cjs/modules/encryptors';

const StorageManagerContext = createContext({
  getEncryptedDataFromLocalStorage: () => null,
  setEncryptedDataToLocalStorage: () => null,
  getPlainDataFromLocalStorage: () => null,
  setPlainDataToLocalStorage: () => null,
  removeDataFromLocalStorage: () => null,
});

const StorageManagerProvider = ({ children }) => {
  const { decrypt, encrypt } = useAes();

  const encryptOldLocalStorage = localStorageKey =>
    localStorage.setItem(
      localStorageKey,
      encrypt(localStorage.getItem(localStorageKey)),
    );

  const getEncryptedDataFromLocalStorage = localStorageKey => {
    const localStorageData = localStorage.getItem(localStorageKey);

    if (!!localStorageData) {
      const decryptedData = decrypt(localStorageData);

      if (decryptedData) {
        return JSON.parse(decryptedData);
      }
      return encryptOldLocalStorage(localStorageKey);
    }
  };

  const setEncryptedDataToLocalStorage = (
    localStorageKey,
    dataForLocalStorage,
  ) => {
    if (!!dataForLocalStorage) {
      const encryptedData = encrypt(JSON.stringify(dataForLocalStorage));
      localStorage.setItem(localStorageKey, encryptedData);
    }
  };

  const removeDataFromLocalStorage = localStorageKey =>
    localStorage.removeItem(localStorageKey);

  const getPlainDataFromLocalStorage = localStorageKey => {
    const localStorageData = localStorage.getItem(localStorageKey);
    if (!!localStorageData) {
      return JSON.parse(localStorageData);
    }
  };

  const setPlainDataToLocalStorage = (localStorageKey, dataForLocalStorage) => {
    if (!!dataForLocalStorage) {
      localStorage.setItem(localStorageKey, dataForLocalStorage);
    }
  };

  return (
    <StorageManagerContext.Provider
      value={{
        getEncryptedDataFromLocalStorage,
        setEncryptedDataToLocalStorage,
        getPlainDataFromLocalStorage,
        setPlainDataToLocalStorage,
        removeDataFromLocalStorage,
      }}>
      {children}
    </StorageManagerContext.Provider>
  );
};

export { StorageManagerContext, StorageManagerProvider };

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faKey,
  faUser,
  faUndo,
  faSpinner,
  faCog,
  faSignOutAlt,
  faUserCircle,
  faBars,
  faChevronRight,
  faPaperPlane,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faUpload,
  faCheck,
  faCamera,
  faExclamationCircle,
  faExclamationTriangle,
  faShieldAlt,
  faInfoCircle,
  faArrowLeft,
  faTimes,
  faCaretRight,
  faCaretLeft,
} from '@fortawesome/free-solid-svg-icons';

import {
  faComment,
  faTimesCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-regular-svg-icons';

const useFontAwesome = () => {
  const registerFaLibrary = () => {
    return library.add(
      faEnvelope,
      faKey,
      faUser,
      faSpinner,
      faUndo,
      faCog,
      faSignOutAlt,
      faUserCircle,
      faBars,
      faComment,
      faPaperPlane,
      faChevronRight,
      faChevronLeft,
      faChevronDown,
      faChevronUp,
      faBars,
      faComment,
      faUpload,
      faCheck,
      faCamera,
      faExclamationCircle,
      faExclamationTriangle,
      faShieldAlt,
      faInfoCircle,
      faArrowLeft,
      faTimesCircle,
      faPaperPlane,
      faEye,
      faEyeSlash,
      faTimes,
      faCaretRight,
      faCaretLeft,
    );
  };

  return {
    registerFaLibrary,
  };
};

export default useFontAwesome;

import React, { useContext, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Icon } from 'modules/components/icon';
import BottomSheetContext from './bottom-sheet.context';

import 'modules/components/bottom-sheet/bottom-sheet.scss';

const BottomSheet = React.forwardRef(props => {
  /* prettier-ignore */
  const {
    className,
    children,
  } = props;

  const snackbarCtx = useContext(BottomSheetContext);
  const { isVisible, setRef, closeBottomSheet } = snackbarCtx;

  const [rootEl, setRootEl] = useState(null);
  const snackbarRef = useRef();
  const classes = classNames(`BottomSheet`, className);

  useEffect(() => {
    if (isVisible) {
      setRef(snackbarRef);
    }
  }, [isVisible]);

  useEffect(() => {
    let outerRoot = document.getElementById('outer-root');
    if (!outerRoot) {
      outerRoot = document.createElement('div');
      outerRoot.setAttribute('id', 'outer-root');
      document.body.appendChild(outerRoot);
    }
    setRootEl(outerRoot);
  }, []);

  return isVisible
    ? createPortal(
        <div ref={snackbarRef} className={classes}>
          <div className="BottomSheet-dialog">
            <div className="BottomSheet-body">
              <Icon
                fa={['fas', 'times']}
                size="lg"
                onClick={closeBottomSheet}
                id="BtnCloseBottomSheet"
              />
              <div>{children}</div>
            </div>
          </div>
        </div>,
        rootEl,
      )
    : null;
});

export default BottomSheet;

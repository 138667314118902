import env from './env';
import {
  GTM_ENV,
  NOTIFICATION_OVO_NICEPAY,
  ZENDESK_URL,
  MINIMAL_SAVING_AMOUNT,
} from 'modules/constants/api-env.const';
import { NEW_RELIC_SCRIPT } from 'modules/constants/new-relic.const';

export default {
  HOST_URL: process.env.RAZZLE_HOST_API_URL,
  SCRIPT_GTM: GTM_ENV[env],
  NOTIFICATION_OVO_NICEPAY: NOTIFICATION_OVO_NICEPAY[env],
  CMS_URL: process.env.RAZZLE_CMS_URL,
  ZENDESK_URL: ZENDESK_URL[env],
  NEW_RELIC_SCRIPT: NEW_RELIC_SCRIPT[env],
  MINIMAL_SAVING_AMOUNT: MINIMAL_SAVING_AMOUNT[env],
  REGION_URL: process.env.RAZZLE_REGION_URL,
};

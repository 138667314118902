import React from 'react';
import MainRoutes from './main.routes';
import { BottomSheetProvider } from 'modules/components/bottom-sheet';
import useConnection from 'modules/services/connection.hook';
import useFontAwesome from 'modules/services/font-awesome.hook';
import { NodeManager } from 'react-register-nodes';
import { OAuthProvider } from '@tunaiku.npm/react-library/dist/cjs/modules/oauth';
import { ApiInvokerProvider } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import { OAUTH as OAUTH_CONFIG } from 'modules/env';
import { API } from 'modules/env';
import useUrlUtm from 'modules/services/url-utm.hook';
import { Loading, LoadingProvider } from 'modules/components/loading';

import './main.scss';

const { HOST_URL } = API;

const Main = () => {
  const { registerFaLibrary } = useFontAwesome();

  registerFaLibrary();
  useConnection();
  useUrlUtm();

  const getPrivateHeader = token => ({ Authorization: `Bearer ${token}` });

  const onErrorUnauthorized = handleRefreshToken => async (
    error,
    apiInvoker,
  ) => {
    const originalRequest = error.config;
    const accessToken = await handleRefreshToken();
    if (accessToken) {
      const newRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          ...getPrivateHeader(accessToken),
        },
      };
      return apiInvoker(newRequest);
    }
  };

  return (
    <div className="Main">
      <div className="Main-container">
        <LoadingProvider>
          <Loading />
          <BottomSheetProvider>
            <NodeManager>
              <OAuthProvider
                initConfig={{ flow: 'standard', timeskew: 0 }}
                baseConfig={OAUTH_CONFIG}
                appConfig={{
                  loginUrl: '/login',
                  privateRootUrl: '/dashboard',
                }}>
                {({ token, handleRefreshToken }) => (
                  <ApiInvokerProvider
                    hostUrl={HOST_URL}
                    headers={getPrivateHeader(token)}
                    listeners={{
                      onErrorUnauthorized: onErrorUnauthorized(
                        handleRefreshToken,
                      ),
                    }}>
                    <MainRoutes />
                  </ApiInvokerProvider>
                )}
              </OAuthProvider>
            </NodeManager>
          </BottomSheetProvider>
        </LoadingProvider>
      </div>
    </div>
  );
};

export default Main;

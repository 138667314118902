import React from 'react';
import { withRouter, Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import useRouteList from './route-list.hook';
import { withOAuthRoute } from '@tunaiku.npm/react-library/dist/cjs/modules/oauth';

const RouteList = ({ routes, match, location }) => {
  useRouteList();
  return (
    <Switch>
      {routes.map(route => {
        const routeUrl =
          match.url === '/' ? `${route.path}` : `${match.url}${route.path}`;

        return <Route {...route} key={location.pathname} path={routeUrl} />;
      })}
      <Route render={() => <Redirect to="/not-found" />} />
    </Switch>
  );
};

export default withRouter(withOAuthRoute(RouteList));

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './app';
import env from 'modules/env/env';
import { version } from '../package.json';
import { BUGSNAG_KEY } from 'modules/constants/bugsnag.const';

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: env,
  appVersion: version,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
// We automatically serve in server so we don't need to have multiple conditions to serve
// using either render() or hydrate() on dev/prod env. Hydrate 💦 all the way!
loadableReady(() => {
  hydrate(
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}

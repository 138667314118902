import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import BottomSheetContext from './bottom-sheet.context';

export const BottomSheetProvider = props => {
  const { children } = props;

  const [value, setValue] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const [ref, setRef] = useState(null);

  const openBottomSheet = obj => {
    setValue(obj.default);

    setVisibility(true);
  };

  const beforeClosing = () => {
    if (ref && ref.current) {
      ref.current.className = classNames('BottomSheet', 'is-hiding');
    }
  };

  const closeBottomSheet = () => {
    beforeClosing();
    setVisibility(false);
  };

  useEffect(() => {
    if (isVisible && ref) {
      ref.current.className = classNames('BottomSheet', 'is-opening');
    }
  }, [isVisible, ref]);

  return (
    <BottomSheetContext.Provider
      value={{
        isVisible,
        openBottomSheet,
        closeBottomSheet,
        setRef,
        value,
      }}>
      {children}
    </BottomSheetContext.Provider>
  );
};

export default BottomSheetProvider;

import React, { useContext } from 'react';
import { EncryptorProvider } from '@tunaiku.npm/react-library/dist/cjs/modules/encryptors';
import Main from './pages/main';
import UnderConstruction from 'pages/under-construction';
import { SnackbarProvider } from 'modules/components/snackbar';
import ScriptInjection from 'modules/components/script-injection';
import {
  UnderConstructionProvider,
  UnderConstructionContext,
} from 'pages/under-construction/under-construction.provider';
import { StorageManagerProvider } from 'modules/services/storage-manager.provider';
import { API } from 'modules/env';
import './assets/styles/main.scss';

const App = () => {
  return (
    <UnderConstructionProvider>
      <InnerApp />
    </UnderConstructionProvider>
  );
};

const InnerApp = () => {
  const { isUnderConstruction } = useContext(UnderConstructionContext);

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <EncryptorProvider secretKey={process.env.RAZZLE_ENCRYPTOR_SECRET_KEY}>
      <StorageManagerProvider>
        <SnackbarProvider>
          <Main />
          <ScriptInjection
            async
            defer
            content={API.SCRIPT_GTM}
            type="text/javascript"
          />
          <ScriptInjection
            async
            defer
            content={API.SCHEMA_WEBSITE}
            type="application/ld+json"
          />
          <ScriptInjection
            async
            defer
            content={API.SCHEMA_BUSINESS}
            type="application/ld+json"
          />
          <ScriptInjection
            async
            defer
            content={API.SCHEMA_FAQ}
            type="application/ld+json"
          />
          <ScriptInjection
            src={API.ZENDESK_URL}
            id="ze-snippet"
            onload={() => window.zE(() => window.zE.hide())}
          />
          <ScriptInjection
            async
            defer
            content={API.NEW_RELIC_SCRIPT}
            type="text/javascript"
          />
        </SnackbarProvider>
      </StorageManagerProvider>
    </EncryptorProvider>
  );
};

export default App;

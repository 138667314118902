import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Snackbar from './snackbar';
import SnackbarContext from './snackbar.context';

/**
 * @name SnackbarProvider
 * @desc A context provider for Snackbar component.
 * @param {*} param children | Pass children prop to display all content inside Provider.
 * @return <SnackbarContext.Provider />
 */

const SnackbarProvider = ({ children }) => {
  const [snackbarClassName, setSnackbarClassName] = useState('');
  const [value, setValue] = useState('');
  const [visible, setVisibility] = useState(false);
  const [timeouts, setTimeouts] = useState([]);
  const rootElement = () => document.getElementById('outer-root');

  useEffect(() => {
    if (visible) {
      setTimeouts([...timeouts, setTimeout(() => closeSnackbar(), 5000)]);
      setSnackbarClassName('is-opening');
    }
  }, [visible]);

  const openSnackbar = obj => {
    if (visible) {
      closeSnackbar();
    }

    setValue(obj.value);

    clearTimeouts();
    setTimeouts([...timeouts, setTimeout(() => setVisibility(true), 500)]);
  };

  const beforeClosing = () => {
    setSnackbarClassName('is-hiding');
  };

  const closeSnackbar = () => {
    beforeClosing();

    return setTimeouts([
      ...timeouts,
      setTimeout(() => setVisibility(false), 250),
    ]);
  };

  const clearTimeouts = () => {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }

    setTimeouts([]);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      {visible &&
        createPortal(
          <Snackbar className={snackbarClassName}>{value}</Snackbar>,
          rootElement(),
        )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

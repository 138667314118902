import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';

const defaultProps = {
  tag: 'span',
  spin: false,
};

const Icon = props => {
  // prettier-ignore
  const {
    tag: Tag,
    fa,
    size,
    theme,
    className,
    spin,
    ...attributes
  } = props;

  const classes = classNames(
    `Icon`,
    theme ? `Color-${theme}` : null,
    className,
  );

  const faProps = {
    icon: fa,
    size,
    spin,
  };

  return (
    <Tag {...attributes} className={classes}>
      <Fa {...faProps} />
    </Tag>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;

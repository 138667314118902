import { useContext } from 'react';
import { StorageManagerContext } from 'modules/services/storage-manager.provider';

const useStorageManager = () => {
  const {
    getEncryptedDataFromLocalStorage,
    setEncryptedDataToLocalStorage,
    getPlainDataFromLocalStorage,
    setPlainDataToLocalStorage,
    removeDataFromLocalStorage,
  } = useContext(StorageManagerContext);

  return {
    getEncryptedDataFromLocalStorage,
    setEncryptedDataToLocalStorage,
    getPlainDataFromLocalStorage,
    setPlainDataToLocalStorage,
    removeDataFromLocalStorage,
  };
};

export default useStorageManager;

import { useEffect, useContext } from 'react';
import { SnackbarContext as Snackbar } from 'modules/components/snackbar';

const useConnection = () => {
  const { openSnackbar } = useContext(Snackbar);

  const handleConnectionStatus = event => {
    const { type } = event;

    if (type === 'online') {
      // do something if connection is found
      return openSnackbar({ value: 'Woohoo, you are back online!' });
    }

    if (type === 'offline') {
      // else do something is connection is lost
      return openSnackbar({
        value: 'Oops, you are offline! Please check your internet connection.',
      });
    }
  };

  const listenEvents = () => {
    window.addEventListener('online', handleConnectionStatus);
    window.addEventListener('offline', handleConnectionStatus);
  };

  const removeEvents = () => {
    window.removeEventListener('online', handleConnectionStatus);
    window.removeEventListener('offline', handleConnectionStatus);
  };

  useEffect(() => {
    listenEvents();
    return () => removeEvents();
  });
};

export default useConnection;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useRouteList = () => {
  const history = useHistory();

  useEffect(() => unlisten(), []);

  const unlisten = history.listen(() => {
    window.scrollTo(0, 0);
  });
};

export default useRouteList;

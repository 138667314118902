import { useEffect } from 'react';

// Component to inject array of scripts
const ScriptInjection = ({ onLoad, onError, ...props }) => {
  useEffect(() => {
    let script = document.createElement('script');

    for (const key in props) {
      if (props.hasOwnProperty(key) && props[key]) {
        if (key !== 'content') {
          script[key] = props[key];
        } else {
          script.text = props.content;
        }
      }
    }

    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);

    // Add script to document body
    document.body.appendChild(script);

    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener('load', onLoad);
      script.removeEventListener('error', onError);
    };
  }, []);

  return '';
};

export default ScriptInjection;

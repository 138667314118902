import React from 'react';
import { ReactComponent as ErrorIcon } from 'assets/images/icon-error.svg';

const UnderConstruction = () => {
  return (
    <div className="BgColor-lighter">
      <div className="Flex AlignItems-center" style={{ minHeight: '100vh' }}>
        <div className="Container Align-center">
          <div className="MarginBottom-large">
            <ErrorIcon style={{ width: '136px' }} />
          </div>
          <h1 className="Heading-6 Title">
            Website sedang dalam tahap pengembangan
          </h1>
          <p>
            Terima kasih untuk kesabaran Anda. Apabila ada pertanyaan, Anda
            dapat menghubungi call center Tunaiku di nomor 021-40002979 (hari
            kerja).
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;

import React from 'react';
import loadable from '@loadable/component';
import { RouteList } from 'modules/components/routes';

export const routes = [
  {
    path: '/',
    exact: true,
    component: loadable(() => import('../pages/landing')),
  },
  {
    path: '/registration',
    component: loadable(() => import('./registration')),
  },
  {
    path: '/login',
    component: loadable(() => import('./login')),
  },
  {
    path: '/dashboard',
    private: true,
    component: loadable(() => import('./dashboard')),
  },
  {
    path: '/about-us',
    component: loadable(() => import('./public-pages/about-us')),
  },
  {
    path: '/contact-us',
    component: loadable(() => import('./public-pages/contact-us')),
  },
  {
    path: '/privacy-policy',
    component: loadable(() => import('./public-pages/privacy-policy')),
  },
  {
    path: '/terms-and-condition',
    component: loadable(() => import('./public-pages/terms-and-condition')),
  },
  {
    path: '/not-found',
    component: loadable(() => import('./public-pages/feedback-404')),
  },
  {
    path: '/information-debit',
    component: loadable(() => import('./dashboard/top-up/information-debit')),
  },
];

export default () => <RouteList routes={routes} />;

import React, { createContext } from 'react';

const UnderConstructionContext = createContext({
  isUnderConstruction: false,
});

const UnderConstructionProvider = ({ children }) => {
  // This will turn the site into under construction mode, which is not accessible
  // to both guests and registered users. Only use this when needed.
  const isUnderConstruction = false;

  return (
    <UnderConstructionContext.Provider value={{ isUnderConstruction }}>
      {children}
    </UnderConstructionContext.Provider>
  );
};

export { UnderConstructionContext, UnderConstructionProvider };

export const LOCAL_STORAGE = {
  customer: '_tnkCustomer',
  registration: '_tnkRegistration',
  verification: '_tnkVerification',
  customerRegistration: '_tnkCustomerRegistration',
  reVerification: '_tnkReverification',
  registrationSuccess: '_tnkRegistrationSuccess',
  resultCalculation: '_tnkResultCalculation',
  topUp: '_tnkTopUp',
  utmSource: '_tnkUtmSource',
  dhnBlackList: '_dhnBlackList',
  idEmailVerification: '_tnkIdEmail',
  depositProcess: '_tnkDpsProcess',
  idToken: 'id_token',
  accessToken: 'access_token',
  customerKycStatus: '_tnkCustomerKycStatus',
};

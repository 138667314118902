import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'querystring';
import useStorageManager from 'modules/services/storage-manager.hook';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';

const useUrlUtm = () => {
  const location = useLocation();
  const paramUrl = location.search;
  const formattedParamUrl = paramUrl.replace('?', '');
  const [utmData, setUtmData] = useState({});
  const {
    getPlainDataFromLocalStorage,
    setPlainDataToLocalStorage,
  } = useStorageManager();

  useEffect(() => {
    handleLocalStorageUtmSource();
    setUtmData(getLocalStorageUtmSource());
  }, []);

  const handleLocalStorageUtmSource = () =>
    paramUrl ? setLocalStorageUtmSource() : utmData;

  const getLocalStorageUtmSource = () =>
    getPlainDataFromLocalStorage(LOCAL_STORAGE.utmSource);

  const setLocalStorageUtmSource = () =>
    setPlainDataToLocalStorage(
      LOCAL_STORAGE.utmSource,
      JSON.stringify(getParamUrlToJson()),
    );

  const getParamUrlToJson = () => querystring.parse(formattedParamUrl);

  return {
    getParamUrlToJson,
  };
};

export default useUrlUtm;

import React, { useContext } from 'react';
import LoadingContext from './loading.context';

import 'modules/components/loading/loading.scss';

const Loading = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    isLoading && (
      <div className="Load-wrap">
        <p>Loading</p>
        <div className="Load-line"></div>
        <div className="Load-line"></div>
        <div className="Load-line"></div>
      </div>
    )
  );
};

export default Loading;

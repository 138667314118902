import React, { useState } from 'react';
import LoadingContext from './loading.context';

export const LoadingProvider = props => {
  const { children } = props;

  const showLoading = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    document.body.style.overflow = 'hidden';
  };

  const hideLoading = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        isLoading: !prevState.isLoading || false,
      };
    });
    document.body.style.overflow = null;
  };

  const loadingState = {
    isLoading: false,
    showLoading,
    hideLoading,
  };

  const [loading, toggleLoading] = useState(loadingState);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
